<template>
  <div id="app">
    <div class="err_message" v-if="screen === 'ERROR_PAGE'">
      {{ error }}
    </div>
    <div class="box" v-if="screen === 'AUTH' || screen === 'CHEK_CODE' ">
      <div class="square" style="--i:0;"></div>
      <div class="square" style="--i:1;"></div>
      <div class="square" style="--i:2;"></div>
      <div class="square" style="--i:3;"></div>
      <div class="square" style="--i:4;"></div>
    </div>

    <div class="form_" v-if="screen === 'AUTH'">
      <h2>Авторизация</h2>
      <input type="text" v-model="login" placeholder="Email или телефон" />
      <button @click="getKey()">Войти</button>
    </div>
    <div class="form_" v-if="screen === 'CHEK_CODE'">
      <h2>Авторизация</h2>
      <p>На указанный Вами номер телефона/e-mail отправлен код подтверждения</p>

      <input type="text" v-model="key" placeholder="Введите код" />
      <button @click="checkKey()">Подтвердить</button>
    </div>

    <!-- создание игровой сессии -->
    <div
      class="app_window app_flex_column bsh"
      v-if="screen === 'CREATE_SESSION'"
    >
      <img class="img_close" src="./assets/delete.png" @click="closeForm" />
      <div class="app_flex_row_start">
        <h4>Создание нового сеанса игры</h4>
      </div>
      <div class="create-game-content">
        <!-- Настройки игры  -->
        <div class="app_flex_column">
          <div class="app_flex_column space_between">
            <label class="blue"> Выберите дату игры </label>
            <input type="date" v-model="newGame.date" />
          </div>
          <div class="app_flex_column">
            <label class="blue">Выберите время игры </label>
            <input type="time" v-model="newGame.time" />
          </div>

          <div class="app_flex_column">
            <label class="blue">Выберите уровень сложности игры </label>
            <div class="radio">
              <input class="radio_input" type="radio" name="lavel" id="simple" v-model="newGame.level" value="SIMPLE" />
              <label class="radio_label" for="simple">Простой</label>
              <input class="radio_input" type="radio"  id="hard" name="lavel" v-model="newGame.level" value="hard" disabled/>
              <label class="radio_label" for="hard">Сложный</label>
            </div>
          </div>
          <div class="app_flex_column">
            <label class="blue">Выберите режим игры </label>
            <div class="radio">
              <input class="radio_input"
                type="radio"
                id="learn"
                name="mode"
                v-model="newGame.mode"
                value="LEARN"
                disabled
              />
              <label class="radio_label" for="learn">Обучающий</label>
              <input class="radio_input"
                type="radio"
                id="play"
                name="mode"
                v-model="newGame.mode"
                value="PLAY"
              />
              <label class="radio_label" for="play">Игровой</label>
            </div>
          </div>
          <div class="app_flex add_new_gamer" v-show="showNewGamer">
            <img class="img_icon" src="./assets/alert-circle.png" />
            <div class="blue" @click="addFormForGamer(1)">
              Добавить участника
            </div>
          </div>
        </div>

        <!-- добавить участников -->

        <div
          class="form_for_new_gamer"
          v-for="n in 50"
          :key="n"
          :data-gamer-id="n"
          style="display: none"
        >
          <div class="gamer_info">
            <input class="gamer_name" type="text" placeholder="Имя" />
            <input class="gamer_surname" type="text" placeholder="Фамилия" />
            <input
              class="gamer_phone"
              type="text"
              placeholder="Номер телефона "
            />
            <input class="gamer_email" type="text" placeholder="e-mail" />

            <select class="gamer_role" name="role" id="">
              <option value="GAMER">Участник</option>
              <option value="VIEWER">Наблюдатель</option>
            </select>

            <div class="app_flex add_new_gamer" :data-button-id="n">
              <img class="img_icon" src="./assets/alert-circle.png" />
              <div class="blue" @click="addFormForGamer(n + 1)">
                Добавить участника
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="app_flex space_between">
        <button class="btn" @click="saveGame(newGame.id)">Сохранить</button>
        <button class="btn" @click="closeForm()">Закрыть</button>
      </div>
    </div>

    <!-- Список игровых сессий -->
    <div class="app_window bsh" v-if="screen === 'GAME_LIST'">
      <!-- список  доступных игр  -->
      <div class="header app_flex">
        <div class="t_games_platform">TGames Platform</div>
        <div class="game_name">Networker</div>
        <div class="master">{{ activeGamer.name }}</div>
      </div>

      <div class="games">
        <div class="app_flex_row_start">
          <h2 class="networker">Доступные игры</h2>
          <!-- <p class="networker">Networker</p> -->
          <img
            class="img_icon"
            src="./assets/alert-circle.png"
            @click="createGame()"
          />
          <p class="blue">Создать новую игру</p>
        </div>

        <div
          class="game-list app_flex_row_start wrag"
          v-for="game in gameList"
          :key="game.id"
        >
          <div class="app_flex wrap ">
            <div class="grey mtop20 mright20">
              {{ game.date }} в {{ game.time }}
            </div>
            <div class="mtop20">
              Игра на {{ game.gamersCount }} человек(а)
            </div>
          </div>

          <div class="container_btn">
            <button @click="openGame(game.id)">Перейти</button>
            <button @click="changeGame(game.id)">Изменить</button>
            <button @click="deleteGame(game.id)">Удалить</button>
          </div>
        </div>
      </div>
    </div>

    <!-- модальное окно создание игры -->

    <StartGame
      class="start-game app_window bsh"
      v-if="screen === 'START'"
      :gamersList="gamersList"
      :viewers="viewers"
      :game="game"
      :buttonLabel="buttonLabel"
      @startGame="startGame"
    />
    <CircleOne
      class="circle_one app_window bsh"
      v-if="screen === 'ONE'"
      :gamersList="gamersList"
      @endGame="endGame"
      @endCircleOne="endCircleOne"
    />
  </div>
</template>

<script>
import StartGame from './components/StartGame.vue'
import CircleOne from './components/CircleOne.vue'
import gamers from './data/gamers.json'
import cards from './data/cards.json'
import axios from './plugins/axios/index'

export default {
  name: 'App',
  data () {
    return {
      screen: '',
      buttonLabel: '',
      newGamer: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        role: ''
      },
      login: '',
      key: '',
      activeGamer: {
        name: '',
        photo: '',
        role: ''
      },
      showNewGamer: true,
      gamersCount: 0,
      cards,
      gamers,
      gamersList: [],
      viewers: [],
      cardsList: [],
      game: {
        date: null,
        time: null,
        level: 'SIMPLE',
        mode: 'PLAY',
        gamersList: []
      },
      newGame: {
        date: null,
        time: null,
        level: 'SIMPLE',
        mode: 'PLAY',
        gamersList: []
      },
      gameList: [
        {
          date: '12/05/2022',
          time: '12:00:00',
          gamersCount: 5,
          level: 'SIMPLE',
          mode: 'PLAY'
        },
        {
          date: '14/06/2022',
          time: '12:00:00',
          gamersCount: 5,
          level: 'SIMPLE',
          mode: 'PLAY'
        },
        {
          date: '16/07/2022',
          time: '12:00:00',
          gamersCount: 5,
          level: 'SIMPLE',
          mode: 'PLAY'
        }
      ],
      isFormVisible: false,
      message: '',
      error: '',
      showError: false
    }
  },
  components: {
    StartGame,
    CircleOne
  },
  async created () {
    var that = this
    await axios
      .get('/init')
      .then(function (resp) {
        if (resp.data.result) {
          // нужно еще роль!!!
          that.activeGamer.name = resp.data.name
          that.activeGamer.photo = resp.data.photo
          that.getGameSession()
        } else {
          that.message = 'Вы не авторизованы, введите логин'
          that.screen = 'AUTH'
        }
      })
      .catch(function (error) {
        console.log(error)
        alert(error)
        that.error = error
      })
  },
  methods: {
    async getKey () {
      var that = this
      const login = {
        login: this.login
      }
      await axios
        .post('/auth/request', login)
        .then(function (resp) {
          if (resp.data.result) {
            that.screen = 'CHEK_CODE'
          } else {
            that.message = 'Пользователь не найден. Обратитесь к организатору'
          }
        })
        .catch(function (error) {
          alert(error)
          that.screen = 'ERROR_PAGE'
          that.error = error
        })
    },
    async checkKey () {
      var that = this
      const key = {
        code: this.key
      }
      await axios
        .post('/auth', key)
        .then(function (resp) {
          if (resp.data.result) {
            that.getGameSession()
          }
        })
        .catch(function (error) {
          alert(error)
          that.error = error
        })
    },
    async getGameSession () {
      var that = this
      await axios
        .get('/game/session')
        .then(function (resp) {
          if (resp.data.result) {
            var gameNetvorker = resp.data.games.find((game) => game.id === '1')

            var sessions = gameNetvorker.sessions
            var gamelist = []
            for (var i = 0; i < sessions.length; i++) {
              var session = sessions[i]
              var timestamp = session.startTime
              var date = new Date(timestamp * 1000)

              var game = {
                gamersCount: session.gamersCount,
                id: session.id,
                level: session.level,
                mode: session.mode,
                date: date.toLocaleDateString(),
                time: date.toLocaleTimeString()
              }

              gamelist.push(game)
            }
            that.gameList = gamelist
            that.screen = 'GAME_LIST'
          }
        })
        .catch(function (error) {
          alert(error)
        })
    },

    addFormForGamer (id) {
      this.gamersCount = id
      this.showNewGamer = false
      var newform = document.querySelector('[data-gamer-id = "' + id + '"]')
      newform.style.display = 'flex'
      if (id > 1) {
        var oldButn = document.querySelector(
          '[data-button-id  = "' + (id - 1) + '"]'
        )
        oldButn.style.display = 'none'
      }
    },
    endCircleOne () {
      this.showCircleOne = false
      this.showStartGame = true
      this.circle = 3
    },
    endGame () {
      this.showFirst = true
      this.showStartGame = false
      this.showCircleOne = false
    },
    startGame () {
      // this.showStartGame = false
      // this.showCircleOne = true
      this.screen = 'ONE'
    },
    async openGame (id) {
      var that = this
      await axios
        .get('/game/session/' + id)
        .then(function (resp) {
          console.log(resp.status)
          if (resp.status === 200) {
            var data = resp.data
            that.screen = data.screen
            console.log(that.screen)
            that.activeGamer.role = data.role // тут не нужна роль
            that.buttonLabel = data.params.buttonLabel
            that.gamersList = data.params.gamers
            that.viewers = data.params.viewers
          }
        })
        .catch(function (error) {
          alert(error)
          that.error = error
        })
    },
    createGame () {
      this.screen = 'CREATE_SESSION'
    },
    closeForm () {
      this.screen = 'GAME_LIST'
    },
    changeGame (id) {
      this.screen = 'CREATE_SESSION'
      this.newGame = this.gameList[id]
      this.newGame.id = id
    },
    deleteGame (id) {
      if (id !== undefined) {
        this.gameList.splice(id, 1)
      }
    },
    async createGameSession (game) {
      var that = this

      await axios
        .post('/game/session', game)
        .then(function (resp) {
          console.log(resp.data)
          if (resp.data.result) {
            that.getGameSession()
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    addNewGamer (count) {
      for (var i = 1; i <= count; i++) {
        var gamerForm = document.querySelector('[data-gamer-id = "' + i + '"]')
        var name = gamerForm.querySelector('.gamer_name').value
        var surname = gamerForm.querySelector('.gamer_surname').value
        var phone = gamerForm.querySelector('.gamer_phone').value
        var email = gamerForm.querySelector('.gamer_email').value
        var role = gamerForm.querySelector('.gamer_role').value

        var newGamer = {
          name: name,
          surname: surname,
          phone: phone,
          email: email,
          role: role
        }

        this.newGame.gamersList.push(newGamer)
      }
    },
    saveGame (id) {
      this.addNewGamer(this.gamersCount)
      var time = new Date(this.newGame.date)
      console.log(this.newGame)
      var game = {
        id: '1',
        startTime: time.getTime(),
        level: this.newGame.level,
        mode: this.newGame.mode,
        gamers: this.newGame.gamersList
      }
      if (id !== undefined) {
        console.log('update')
      } else {
        this.createGameSession(game)
      }

      this.isFormVisible = false
    }
  }
}
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --text_color_gr: #666;
}
body {
  height: 100vh;
  background: linear-gradient(58deg,rgba(195,34,195,.4),rgba(45,253,110,.4) 30%,rgba(63,224,224,.4));
}

#app {
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Comfortaa';
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.box{
  position: relative;
  height: 300px;
  width: 400px;
}
.box .square {
  position: absolute;
  background: rgba(226, 248, 255, 0.1);
  backdrop-filter: blur(6px);
  box-shadow: 0 25px 45px rgb(0,0,0, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.5) ;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  /* animation: animate 5s linear infinite; */
  /* animation-delay: calc(-1s * var(--i)); */
}

.box:hover .square {
 animation: animate 10s linear infinite;
 animation-delay: calc(-1s * var(--i));
}

@keyframes animate {
  0%,100%
  {
    transform: translateY(-40px);
  }
  50%
  {
    transform: translateY(40px);
  }
}
.box.square:nth-child(1) {
  bottom: 20%;
  left: 30%;
  width: 120px;
  height: 120px;
}
.box .square:nth-child(2) {
  top: 10%;
  right: -150%;
  width: 50px;
  height: 50px;
}

.box .square:nth-child(3) {
  bottom: 10%;
  left: 20%;
  width: 50px;
  height: 50px;
}
.box .square:nth-child(4) {
  top: 60px;
  right: -20%;
  width: 200px;
  height: 200px;
  }

.box .square:nth-child(5) {
  top: 40%;
  right: 20%;
  width: 85px;
  height: 85px;
}

button:hover {
  background: #87dbf7;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  color: #707c8b;
}
label {
  padding: 10px 5px 5px 5px;
  font-size: 14px;
}
input,
select {
  margin-top: 20px;
  padding: 10px 20px;
  background: rgb(255, 255, 255, 0.2);
  border: 0;
  outline: 0;
  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.5) ;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  letter-spacing: 1px;
  color: var(--text_color_gr);

  width: 100%;
}
button {
  background: #fff;
  color: #666;
  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.5) ;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 20px;
  font-family: 'Comfortaa';
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-right: 5px;
}
option {
  padding: 7px;
  border-radius: 4px;
  border: 1px solid #cfd3d8;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #ced0d3;
}

.message {
  color: red;
  font-size: 0.7em;
}
.err_message {
  font-size: 1.2em;
}
.add_new_gamer {
  margin-left: auto;
}

.mtop20 {
  margin-top: 20px;
}
.mright20 {
  margin-right: 20px;
}
.bg_blue {
  background: #74d0f0;
}
.bg_grey {
  background: #d3d5d8;
}
.bg_lite_grey {
  background: #f5f5f5;
}

.bg_white {
  background: #ffffff;
}

.border_bottom {
  border-bottom: 1px solid #e8e8e8;
}

.img_icon {
  margin: 3px;
  max-width: 16px;
  max-height: 16px;
}
.bsh {
  margin: 10px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.356);
  border-radius: 4px;
  box-shadow: 0px 3px 14px rgba(15, 61, 116, 0.12);
}

.app_window {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

@media (max-width: 767px) {
  /* стили для xs-устройств */
  .app_window {
    width: 100%;
  }
  .form_ {
    width: 90%;
  }
}

@media  (max-width: 991px) {
  /* стили для sm-устройств */
  .app_window {
    width: 100%;
  }
  .form_ {
    width: 400px;
  }
}

@media  (max-width: 1199px) {
  /* стили для md-устройств */
  .app_window {
    width: 90%;
  }
  .form_ {
    width: 400px;
  }
}

@media (min-width: 1200px) {
  /* стили для lg-устройств */
  .app_window {
    width: 990px;
  }
  .form_ {
    width: 400px;
  }
}

.blue {
  color: #00adea;
}
.grey {
  color: #a0a8b1;
}
.app_flex {
  display: flex;
}
.app_flex_row_start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.app_flex_column {
  display: flex;
  flex-direction: column;
}
.center {
  justify-content: center;
  align-items: center;
}
.space_between {
  justify-content: space-between;
  align-items: space-between;
}
.flex_end {
  justify-content: center;
  align-items: flex-end;
}
.flex_start {
  justify-content: flex-start;
}

.wrap {
  flex-wrap: wrap;
}
.networker {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  margin-right: auto;
  margin-left: 10px;
}
.img_close {
  margin-left: auto;
}
.game-list {
  margin: 3px;
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.game-info {
  padding: 5px;
  margin: 8px;
}
.form_for_new_gamer {
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 10px 0;
  border-bottom: 1px solid #e8e8e8;
}
.gamer_info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 20px 10px 10px 10px;
}

.form_ {
  position: relative;
  margin: 30px 20px;
  min-height: 400px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  background: rgba(226, 248, 255, 0.1);
  backdrop-filter: blur(6px);
  box-shadow: 0 25px 45px rgb(0,0,0, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.5) ;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.form_ h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
  color: rgb(255, 255, 255);
}
.form_ h2::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 70%;
  height: 3px;
  background: rgb(255, 255, 255);
}
.form_ input {
  width: 100%;
  cursor: text;

}
.form_ input::placeholder {
  color: var(--text_color_gr);
  font-family: 'Comfortaa';
}
.form_ button {
  background: #fff;
  color: #666;
  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.5) ;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 20px;
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 20px;
}
.form_ p {
  color: #707c8b;
  font-size: 0.6em;
  padding: 0px 50px 10px 0;
}
.container_btn {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-left: auto;
}
.create-game-content {
  display: flex;
  justify-content: flex-start;
}

.create-game-content .blue {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: rgb(161, 161, 161);
}

.create-game-content input {
  margin-right: 10px;
}

.radio {
  display: inline-flex;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 5px rgb(0,0,0, 0.25);
}
/* .radio input,label {
  margin: 0;
} */
.radio_input {
  display: none;
}
.radio_label {
  padding: 8px 30px;
  min-width: 50%;
  font-size: 14px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background: linear-gradient(rgb(210, 233, 255),rgb(155, 169, 179));
  transition: background 0.1s;
}
.radio_label:not(:last-of-type) {
  border-right: 1px solid rgb(1, 158, 197);;
}

.radio_input:checked + .radio_label {
  background: linear-gradient(#94e2fc, rgb(47, 171, 212));
}
</style>
