<template>
  <div class="start_game">
    <h2>Networker</h2>
    <div class="app_flex">
      <div class="app_flex_column flex_start ">
        <h3>Участники</h3>
        <div v-for="(item, id) in gamersList" :key="id">
          <div class="app_flex center margin_5_10">
            <img src="../assets/a-avatar.png" alt="" />
            <h5 class="item-list">{{ gamersList[id] }}</h5>
          </div>
        </div>
      </div>

      <div class="app_flex_column flex_start">
        <h3>Наблюдатели</h3>
        <div v-for="(item, id) in viewers" :key="id">
          <div class="app_flex center">
            <img src="../assets/a-avatar.png" alt="" />
            <h5 class="item-list">{{ viewers[id] }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="app_flex_column center">
      <h1 class="blue">{{ buttonLabel }}</h1>
      <button class="btn_start_game" @click="startGame()">Начать игру</button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    game: Object,
    buttonLabel: String,
    gamersList: Array,
    viewers: Array
  },
  methods: {
    startGame () {
      if (this.circle === 3) {
        alert('Этот круг еще не реализован')
      } else {
        this.$emit('startGame', this.game)
      }
    }
  }
}
</script>

<style>
.start_game h2{
  margin-bottom: 20px;
}
.start_game h3{
  margin-bottom: 20px;
}
.start_game h1{
  margin-bottom: 20px;
}

.item-list {
  padding: 10px;
}
.btn_start_game {
  justify-self: center;
}
</style>
