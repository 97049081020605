<template>
  <div>
    <div class="header app_flex">
      <div class="t_games_platform">TGames Platform</div>
      <div class="game_name">Networker</div>
      <div class="master">
        <select name="role" id="" v-model="userRole">
          <option value="admin"> Ведущий</option>
          <option value="watcher"> Наблюдатель</option>
          <option value="gamer"> Участник</option>
        </select>
      </div>
    </div>
    <div class="app_flex">
      <div class="cards">
        <img class="img_cards" src="../assets/card.png" alt="" /> Игра (колоды)
      </div>
      <div class="selected_cards bsh">Выбранные карты</div>
    </div>
    <!-- основной контейнер -->
    <div class="app_flex space_between wrap">
      <div class="left_container bsh" v-show="isMainCards">
        <div class="main_cards">Основные колоды</div>
        <div class="values_cantainer">
          <div class="card_values">Эмоции</div>
          <div class="card_values">Ценности</div>
          <div class="card_values">Метафоры</div>
        </div>
        <button v-if="userRole === 'gamer'" @click="select_cards()" >
          Получить три случайные карты
        </button>

        <div class="app_flex center hint">
          <img class="img_lapm" src="../assets/lapm.png" alt="" />
          Текущий участник должен получить 3 случайные карты
        </div>
      </div>
      <!-- выбранные карты, таймер -->
      <div
        class="left_container bsh"
        v-show="isSelected"
      >
        <div class="main_cards">Основные колоды</div>
        <div class="values_cantainer">
          <div class="card_values emoutions">
            {{ cardsList[0] }}
          </div>
          <div class="card_values values">
            {{ cardsList[1] }}
          </div>
          <div class="card_values metaphors">
            {{ cardsList[2] }}
          </div>
        </div>
        <div v-if = "userRole === 'admin'" class="container_for_timers">
          <div class="timer_ app_flex_column bsh" v-show="showMinuts">
            Запустить таймер на (минут)
            <div class="conteiner_minuta app_flex space_between wrap">
              <div class="minuta" @click="startTimer(60)">1</div>
              <div class="minuta" @click="startTimer(120)">2</div>
              <div class="minuta" @click="startTimer(180)">3</div>
              <div class="minuta" @click="startTimer(240)">4</div>
              <div class="minuta" @click="startTimer(300)">5</div>
            </div>
          </div>
          <img
            class="img_timer"
            src="../assets/timer1.png"
            v-show="showMinuts"
            @click="startSec(0)"
          />
        </div>
        <button v-if = "userRole === 'admin'" v-show="showNextStep" @click="go_to_vote()">
          Перейти к голосованию по критериям
        </button>

        <div class="timer_" v-show="showTimer">
          <div class="app_flex space_between">
            <div class="sec">00 : 00</div>
            <div v-if = "userRole === 'admin'" class="app_flex">
              <img
                class="img_pause"
                src="../assets/pause.png"
                @click="pause()"
              />
              <img
                class="img_play"
                src="../assets/play.png"
                @click="startSec(this.timerMinut)"
              />
              <img
                class="img_restart"
                src="../assets/restart.png"
                @click="startSec(0)"
              />
            </div>
          </div>
        </div>

        <div class="timer_" v-show="showProgress">
          <div class="scroll_bar">
            <div class="scroll_bar_done" data-done="100"></div>
          </div>
          <div class="app_flex center">
            <div class="timer">00 : 00</div>
            <div v-if = "userRole === 'admin'" class="app_flex">
              <img
                class="img_pause"
                src="../assets/pause.png"
                @click="pause()"
              />
              <img
                class="img_play"
                src="../assets/play.png"
                @click="startTimer(this.timerMinut)"
              />
              <img
                class="img_restart"
                src="../assets/restart.png"
                @click="startTimer(this.timerMinut)"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- голосование по критериям class="vote -->
      <div v-if = "userRole !== 'admin'" class="left_container  bsh " v-show="showVote">
        <h3>Голосование по критериям</h3>
        <span>Оцените речь участника по каждому критерию:</span>
          <div class="app_flex_column wrap">

              <label >Энергия</label>
              <select name="" id="" v-model="selected.energy" >
                <option v-for="(option,id) in options" :key= "id" :value="option.value" >
                  {{ option.text }}
                </option>
              </select>

              <label >Эмоции</label>
              <select name="" id="" v-model="selected.emoutions" >
                <option v-for="(option,id) in options" :key= "id" :value="option.value" >
                  {{ option.text }}
                </option>
              </select>

              <label >Контакт глаз</label>
              <select name="" id="" v-model="selected.eyes" >
                <option v-for="(eye,id) in eyes" :key= "id" :value="eyes.value" >
                  {{ eye.text }}
                </option>
              </select>

              <label >Жесты</label>
              <select name="" id="" v-model="selected.gestures" >
                <option v-for="(option,id) in options" :key= "id" :value="option.value" >
                  {{ option.text }}
                </option>
              </select>

             <label >Структура речи</label>
              <select name="" id="" v-model="selected.speech" >
                <option v-for="(speec,id) in speech" :key= "id" :value="speech.value" >
                  {{ speec.text }}
                </option>
              </select>

              <label> Слова-паразиты, жаргониpмы, профессионализмы </label>
              <select name="" id="" v-model="selected.parasite_words" >
                <option v-for="(option,id) in options" :key= "id" :value="option.value" >
                  {{ option.text }}
                </option>
              </select>

              <label >Архетип или роль</label>
              <select name="" id="" v-model="selected.archetype" >
                <option v-for="(archetype,id) in archetypes" :key= "id" :value="archetype.value" >
                  {{ archetype.text }}
                </option>
              </select>
          </div>
          <button class="btn_vote" @click="goToResults()">Проголосовать</button>
      </div>
      <!-- голосование результаты -->
      <div class="left_container bsh" v-show="showResults">
        <h3>Голосование по критериям</h3>
        <div class="app_flex">
          <div class="app_flex_column flex_end progress_result_container ">
            <label>Энергия</label>
            <label>Эмоции</label>
            <label>Контакт глаз</label>
            <label>Жесты</label>
            <label>Структура речи</label>
            <label>Слова-паразиты, жаргониpмы, профессионализмы</label>
            <label>Архетип или роль</label>
         </div>
         <div class="app_flex_column progress_result_container ">
          <div class="progress_result app_flex">
            <div class="not_enough"></div>
            <div class="enough"></div>
            <div class="overdo"></div>
          </div>

          <div class="progress_result app_flex">
            <div class="not_enough"></div>
            <div class="enough"></div>
            <div class="overdo"></div>
          </div>

          <div class="progress_result app_flex">
            <div class="yes"></div>
            <div class="no"></div>
          </div>

          <div class="progress_result app_flex">
            <div class="not_enough"></div>
            <div class="enough"></div>
            <div class="overdo"></div>
          </div>

          <div class="progress_result app_flex">
            <div class="not_enough"></div>
            <div class="enough"></div>
            <div class="overdo"></div>
          </div>

          <div class="progress_result app_flex">
            <div class="not_enough"></div>
            <div class="enough"></div>
            <div class="overdo"></div>
          </div>

          <div class="progress_result app_flex">
            <div class="yes"></div>
            <div class="no"></div>
          </div>
        </div>
        </div>
        <button v-if = "userRole === 'admin'" class="btn_go_to_questions" @click="go_to_questions()">
          Перейти к вопросам и возражениям
        </button>

      </div>
      <!-- колода вопросы выбрать случайный -->
      <div
        class="left_container  bsh"
        v-show="showQuestions"
      >
        <div class="main_cards">Основные колоды</div>
        <div class="values_cantainer">
          <div class="card_values">Вопросы</div>
          <!-- <div class="hint"> Участник должен выбрать случайный вопрос</div> -->
        </div>

        <button v-if = "userRole === 'gamer'" class="btn_get_questions" @click="select_question()">
          <!-- <img class="img_dice" src="../assets/dice.png" /> -->
          Выбрать случайный вопрос
        </button>
      </div>
      <!-- вопрос + перейти к возражениям -->
      <div class="left_container bsh " v-show="showQuestion">
        <div>Задайте вопрос:</div>
        <div class="values_cantainer">
          <div class="card_values questions">{{ cardsList[3] }}</div>
        </div>
        <button v-if = "userRole === 'admin'" class="go_to_objections" @click="go_to_objections()">
          Перейти к возражениям
        </button>
        <div class="app_flex hint_50">
          <div class="ask">
            <h5>Задает вопрос</h5>
            <p>{{ gamersList[this.activeGamer + 1] }}</p>
          </div>
          <div class="ask">
            <h5>Отвечает на вопрос</h5>
            <p>{{ gamersList[this.activeGamer] }}</p>
          </div>
        </div>
      </div>
      <!-- выбрать возражение + далее -->
      <div class="left_container bsh " v-show="showObjections">
        <div class="main_cards">Основные колоды</div>
        <div class="values_cantainer">
          <div class="card_values">Возражения</div>
        </div>

        <button v-if = "userRole === 'gamer'" class="btn_get_questions" @click="select_objection()">
          <!-- <img class="img_dice" src="../assets/dice.png" /> -->
          Выбрать случайное возражение
        </button>
      </div>

      <!-- возражение + далее -->
      <div class="left_container bsh " v-show="showObjection">
        <div class="main_cards">Выскажите возражение</div>
        <div class="values_cantainer">
          <div class="card_values objections">{{ cardsList[4] }}</div>
        </div>
        <button v-if = "userRole === 'admin'" class="go_to_objections" @click="go_to_objectionAndNextGamer()">
          Далее >>>>
        </button>
        <div class="app_flex hint_50">
          <div class="ask">
            <h5>Высказывает возражение</h5>
            <p>{{ gamersList[this.activeGamer + 1] }}</p>
          </div>
          <div class="ask">
            <h5>Отвечает на возражение</h5>
            <p>{{ gamersList[this.activeGamer] }}</p>
          </div>
        </div>
      </div>
      <!-- возражение + перейти к следующему -->
      <div
        class="left_container bsh "
        v-show="showObjectionAndNextGamer"
      >
      <div class="values_cantainer">
        <div class="card_values objections">{{ cardsList[4] }}</div>
      </div>

        <button v-if = "userRole === 'admin'" class="go_to_objections" @click="go_to_next_gamer()">
          Перейти к следующему участнику
        </button>
        <div class="app_flex_column">
          <div class="hint_50">
            <h5>Остальные игроки</h5>
            <h5>Дают обратную связь по ответу</h5>
          </div>
        </div>
      </div>
      <!-- конец круга -->
      <div class="left_container bsh " v-show="showEndCircleOne">
        <h3>Подведение итогов</h3>
        <p>1. Что интересного Вы узнали о себе во время игры / этого круга?</p>
        <p>2. Что для вас является зоной роста?</p>
        <p>
          3. Какой навык возьмете вы из этой игры для каждодневного
          использования?
        </p>
        <p>4. Какое выступление "наполнило" вас энергией?</p>
        <p>5. Какое выступление "отняло" у вас энергию?</p>

        <button v-if = "userRole === 'admin'" class="btn_get_questions" @click="end_circle_one()">
          Завершить круг
        </button>
      </div>

      <div class="app_flex_column right_container bsh">
        <div
          class="gamer app_flex"
          v-for="(gamer, id) in gamersList"
          :key="id"
          :data-id="id"
          data-info="active"
        >
          <img class="img_circle" src="../assets/circle.png" />
          {{ gamersList[id] }}
          <img v-if = "userRole === 'admin'"
            class="img_delete"
            src="../assets/delete.png"
            @click="canselGamer(id)"
          />
          <img v-if = "userRole === 'admin'"
            class="img_reload"
            src="../assets/reload.png"
            @click="reloadGamer(id)"
          />
        </div>

        <div v-if = "userRole === 'admin'" class="right_control">
          <button @click="go_to_next_gamer()">
            Перейти к следующему участнику
          </button>
        </div>
      </div>
    </div>
    <div v-if = "userRole === 'admin'" class="game_control">
      <h4>Управление игрой</h4>
      <button  @click="endGame()">Завершить игру</button>
      <button  @click="reloadCircle()">
        Перезапустить круг
      </button>
    </div>
  </div>
</template>

<script>
import cards from '../data/cards.json'

export default {
  data () {
    return {
      userRole: 'gamer',
      archetypes: [
        { text: 'Подходит', value: 'yes' },
        { text: 'Не подходит', value: 'no' }
      ],
      speech: [
        { text: 'Мало', value: 'enough' },
        { text: 'Допустимо', value: 'no_enough' },
        { text: 'Перебор', value: 'overdo' }
      ],
      eyes: [
        { text: 'Да', value: 'yes' },
        { text: 'Нет', value: 'no' }
      ],
      options: [
        { text: 'Хватило', value: 'enough' },
        { text: 'Не хватило', value: 'no_enough' },
        { text: 'Перебор', value: 'overdo' }

      ],
      selected: {
        energy: null,
        emoution: null,
        eyes: null,
        speech: null,
        parasite_words: null,
        archetype: null,
        gestures: null

      },
      cards,
      cardsList: [],
      activeGamer: 0,
      isSelected: false,
      isMainCards: true,
      showMinuts: true,
      showProgress: false,
      showTimer: false,
      showNextStep: false,
      showVote: false,
      showResults: false,
      showQuestions: false,
      showQuestion: false,
      showObjections: false,
      showObjection: false,
      showEndCircleQuestions: false,
      showObjectionAndNextGamer: false,
      showEndCircleOne: false,
      timerMinut: 0,
      timer: null,
      timerRestart: 0,
      start: 280
    }
  },
  props: {
    gamersList: Array
  },
  methods: {
    clear () {
      clearInterval(this.timer)
      this.cardsList = []
      this.isSelected = false
      this.isMainCards = true
      this.showMinuts = true
      this.showProgress = false
      this.showTimer = false
      this.showNextStep = false
      this.showVote = false
      this.showResults = false
      this.showQuestions = false
      this.showQuestion = false
      this.showObjections = false
      this.showObjection = false
      this.showEndCircleQuestions = false
      this.showObjectionAndNextGamer = false
      this.showEndCircleOne = false
      this.timerMinut = 0
      this.timer = null
      this.timerRestart = 0
      this.start = 280
      var selcard = document.querySelector('.selected_cards')
      selcard.style.display = 'none'
      // selcard.style.background = '#f0f0f0'

      var cards = document.querySelector('.cards')
      cards.style.background = '#cbeccd'
      cards.style.display = 'block'
    },
    end_circle_one () {
      var end = confirm('Завершить круг?')
      if (end) {
        this.clear()
        this.activeGamer = 1
        this.$emit('endCircleOne')
      }
    },
    reloadCircle () {
      var end = confirm('Перезапустить круг ?')
      if (end) {
        this.clear()
        this.activeGamer = 1
      }
    },
    go_to_objectionAndNextGamer () {
      this.showObjection = false
      this.showObjectionAndNextGamer = true
    },
    go_to_end_circle () {
      this.showObjection = false
      this.showEndCircle = true
    },
    select_objection () {
      this.showObjections = false
      this.showObjection = true
      this.cardsList.push(this.getCards('objections'))
    },
    go_to_objections () {
      this.showQuestion = false
      this.showObjections = true
    },
    select_question () {
      this.showQuestions = false
      this.showQuestion = true

      this.cardsList.push(this.getCards('questions'))
    },
    go_to_questions () {
      this.showResults = false
      this.showQuestions = true
    },
    goToResults () {
      this.showVote = false
      this.showResults = true
      var radio = document.querySelectorAll('input')
      for (var i = 0; i < radio.length; i++) {
        radio[i].checked = false
      }
    },
    go_to_vote () {
      this.showNextStep = false
      this.isSelected = false
      this.showVote = true
      clearInterval(this.timer)
      this.timer = null
    },
    pause () {
      clearInterval(this.timer)
      var play = document.querySelectorAll('.img_play')
      play.forEach((element) => {
        element.style.display = 'inline-block'
      })
      var pause = document.querySelectorAll('.img_pause')
      pause.forEach((element) => {
        element.style.display = 'none'
      })
    },
    startSec (sec) {
      if (this.timer) {
        clearInterval(this.timer)
        this.timerMinut = 0
      }

      this.showMinuts = false
      this.showTimer = true
      this.showNextStep = true
      this.timerMinut = sec
      var pause = document.querySelectorAll('.img_pause')

      pause.forEach((element) => {
        element.style.display = 'inline-block'
      })
      var play = document.querySelectorAll('.img_play')
      play.forEach((element) => {
        element.style.display = 'none'
      })
      var timerShow = document.querySelector('.sec')
      this.timer = setInterval(() => {
        var second = this.timerMinut % 60
        var minutes = Math.trunc(this.timerMinut / 60) % 60

        minutes = minutes < 10 ? '0' + minutes : minutes
        second = second < 10 ? '0' + second : second

        var strTimer = `${minutes} : ${second}`

        // Выводим строку в блок для показа таймера
        timerShow.innerHTML = strTimer

        ++this.timerMinut // Увеличиваем таймер
      }, 1000)
    },
    startTimer (min) {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }

      this.showMinuts = false
      this.showProgress = true
      this.showNextStep = true
      var pause = document.querySelectorAll('.img_pause')

      pause.forEach((element) => {
        element.style.display = 'inline-block'
      })
      var play = document.querySelectorAll('.img_play')
      play.forEach((element) => {
        element.style.display = 'none'
      })
      var progress = document.querySelector('.scroll_bar_done')
      this.start = progress.getAttribute('data-done')
      progress.style.width = progress.getAttribute('data-done') + '%'
      var timerShow = document.querySelector('.timer')
      this.timerMinut = min
      var delta = parseInt(progress.getAttribute('data-done')) / min
      console.log(delta)
      this.timer = setInterval(() => {
        var second = this.timerMinut % 60
        var minutes = Math.trunc(this.timerMinut / 60) % 60
        minutes = minutes < 10 ? '0' + minutes : minutes
        second = second < 10 ? '0' + second : second

        if (this.timerMinut <= 0) {
          clearInterval(this.timer)
          this.timerMinut = 0
        } else {
          if (this.timerMinut <= 10) {
            timerShow.style.color = 'red'
          }
          var strTimer = minutes + ' : ' + second
          // Выводим строку в блок для показа таймера
          timerShow.innerHTML = strTimer
          this.start -= delta
          progress.style.width = this.start + '%'
        }
        --this.timerMinut // Уменьшаем таймер
      }, 1000)
    },
    select_cards () {
      this.isSelected = true
      this.isMainCards = false

      var el = document.querySelector('.selected_cards')
      el.style.background = '#cbeccd'
      el.style.display = 'block'

      var el2 = document.querySelector('.cards')
      el2.style.display = 'none'

      this.cardsList.push(this.getCards('emoutions'))
      this.cardsList.push(this.getCards('values'))
      this.cardsList.push(this.getCards('metaphors'))
    },
    getCards (cardsName) {
      var obj = cards.find((item) => item.name === cardsName)
      var objValues = obj.values
      var color = Math.floor(Math.random() * 2 + 1)
      var valuesBycolor = objValues.find((item) => item.id === color)
      const valuesByColor1 = valuesBycolor.values

      var element = document.querySelector('.' + cardsName + '')
      if (color === 1) {
        element.style.background = '#f3cbd1'
      } else {
        element.style.background = '#cbeccd'
      }

      return valuesByColor1[Math.floor(Math.random() * valuesByColor1.length)]
    },
    getRandomName () {
      return this.gamers[Math.floor(Math.random() * this.gamers.length)]
    },
    endGame () {
      var end = confirm('Завершить игру?')

      if (end) {
        this.clear()
        this.activeGamer = 1
        this.$emit('endGame')
      }
    },
    canselGamer (id) {
      var gamer = document.querySelector('[data-id = "' + id + '"]')
      gamer.style.background = '#CFD3D9'
      gamer.setAttribute('data-info', '')
      var imgCancel = gamer.querySelector('.img_delete')

      imgCancel.style.display = 'none'
      var imgReload = gamer.querySelector('.img_reload')

      imgReload.style.display = 'inline-block'
    },
    reloadGamer (id) {
      var gamer = document.querySelector('[data-id = "' + id + '"]')
      gamer.style.background = '#ffffff'
      gamer.setAttribute('data-info', 'active')
      var imgCancel = gamer.querySelector('.img_delete')
      imgCancel.style.display = 'inline-block'
      var imgReload = gamer.querySelector('.img_reload')
      imgReload.style.display = 'none'
    },
    go_to_next_gamer () {
      this.clear()
      var activ = document.querySelector(
        '[data-id="' + this.activeGamer + '"]'
      )
      activ.style.background = '#ffffff'

      if (this.nextGamer() === 0) {
        this.showObjectionAndNextGamer = false
        this.showEndCircleOne = true
        this.isMainCards = false
      } else {
        this.activeGamer = this.nextGamer()
        var next = document.querySelector(
          '[data-id="' + this.activeGamer + '"]'
        )
        next.style.background = '#cbeccd'
      }
    },
    nextGamer () {
      var activeList = document.querySelectorAll('[data-info="active"]')
      var nextGamer = 0

      if (this.activeGamer < 5) {
        for (var i = 0; i < activeList.length; i++) {
          var el = activeList[i]
          if (parseInt(el.getAttribute('data-id')) === this.activeGamer) {
            var elnext = activeList[i + 1]
            nextGamer = parseInt(elnext.getAttribute('data-id'))
            break
          }
        }
      }
      return nextGamer
    }
  }
}
</script>

<style>
.width-50 {
  max-width: 45%;
}
.heder {
  padding-bottom: 4px;
  border-bottom: 2px solid rgb(211, 211, 211);
}

.game_control {
  margin-top: 20px;
  padding: 10px;
}
.right_container {
  justify-content: flex-start;
  margin: 10px;
}
.right_control {
  margin-top: 20px;
}
.gamer {
  padding: 10px;
  margin: 0;
  border: 1px solid #d2d4da;
  align-items: center;
}

.gamer:first-child {
  background-color: rgb(203, 236, 205);
  border-radius: 4px 4px 0px 0px;
}

.gamer div :last-of-type {
  border-radius: 0px 0px 4px 4px;
  margin-bottom: 10px;
}
.img_circle {
  max-width: 100%;
  margin-right: 10px;
}
.img_delete {
  margin-left: auto;
}

.img_reload {
  max-width: 20px;
  margin-left: auto;
  display: none;
}

.left_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px;
}

@media (max-width: 767px) {
  /* стили для xs-устройств */
  .left_container {
    width: 100%;
  }
  .right_container {
    width:100%
  }
  .card_values {
    width: 45%;
    font-size: 1em;
    padding: 50px 15px;
    margin: 5px 10px;
  }
  .hint_50 {
    width: 100%;
    background-color: #f4f8e6;
    text-align: center;
    margin: 10px;
    padding: 20px;
    border-radius: 13px;
    border: 1px solid #d2d4da;
    justify-content: space-around
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  /* стили для sm-устройств */
   .left_container {
    flex-basis: 100%;
  }
  .right_container {
    flex-basis: 100%;
  }
  .card_values {
    max-width: 50%;
    flex-basis: 30%;
    font-size: 1em;
    padding: 50px 15px;
    margin: 10px 5px;
  }
  .hint_50 {
    width: 100%;
    background-color: #f4f8e6;
    text-align: center;
    margin: 10px;
    padding: 20px;
    border-radius: 13px;
    border: 1px solid #d2d4da;
    justify-content: space-around
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  /* стили для md-устройств */
  .left_container {
    max-width: 65%;
    flex-basis: 65%;
  }
  .right_container {
    width:30%
  }
  .card_values {
    max-width: 50%;
    flex-basis: 30%;
    font-size: 1em;
    padding: 50px 15px;
    margin: 10px 0;
  }
  .hint_50 {
    width: 50%;
    background-color: #f4f8e6;
    text-align: center;
    margin: 10px;
    padding: 20px;
    border-radius: 13px;
    border: 1px solid #d2d4da;
    justify-content: space-around;
  }
}

@media (min-width: 1200px) {
  /* стили для lg-устройств */
  .left_container {
    max-width: 65%;
    flex-basis: 65%;
  }
  .right_container {
    width:30%
  }
  .card_values {
    max-width: 50%;
    flex-basis: 30%;
    font-size: 1em;
    padding: 50px 15px;
    margin: 10px 0;
  }
  .hint_50 {
    justify-content: space-around;
    width: 50%;
    background-color: #f4f8e6;
    text-align: center;
    margin: 10px;
    padding: 20px;
    border-radius: 13px;
    border: 1px solid #d2d4da;
  }
}
.cards {
  font-size: 10px;
  background-color: rgb(203, 236, 205);
  margin: 10px;
  text-align: center;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0px 3px 14px rgba(15, 61, 116, 0.12);
}
.selected_cards {
  font-size: 10px;
  margin: 10px;
  display: none;
}
.img_cards {
  max-width: 30px;
}
.values_cantainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-self: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: auto;
}

.card_values {
  display: flex;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #d2d4da;
  justify-content: center;
  align-items: center;

}

.img_lapm {
  max-width: 40px;
  margin-right: 10px;
}
.timer_ {
  padding: 10px 15px;
  border-radius: 10px;
  margin: 10px;
}

.img_timer {
  max-height: 50px;
  margin: 20px;
}
.scroll_bar {
  height: 25px;
  border-radius: 10px;
  border: 1px solid rgb(177, 177, 177);
  background-color: rgb(255, 255, 255);
}
.scroll_bar_done {
  margin: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(to right, #ff6a6a, #00ff9c);
}
.timer,
.sec {
  margin: 10px;
  font-variant: small-caps;
  font-size: 2em;
}
.timer {
  margin-right: auto;
}
.img_pause,
.img_restart,
.img_play {
  margin: 10px;
  height: 30px;
  cursor: pointer;
}
.img_restart {
  right: 20px;
}
.img_play {
  display: none;
}
.container_for_timers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.conteiner_minuta {
  z-index: 2;
}
.minuta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7px;
  padding: 15px;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  border: 1px solid rgb(177, 177, 177);
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.hint {
  background-color: #f4f8e6;
  text-align: center;
  margin: 10px;
  padding: 20px;
  border-radius: 18px;
  border: 1px solid #d2d4da;
}
.t_games_platform {
  margin-right: auto;
  padding: 4px;
  font-size: 10px;
}

.game_name {
  font-size: 25px;
  padding-right: 20px;
}
.master {
  padding: 5px 5px 5px 27px;
}

.right_vote {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 10px 20px;
}

.item_vote_container {
  flex-basis: 20%;
}

.item_vote {
  display: flex;
  border-radius: 8px;
  border: 1px solid #cfd3d8;
  padding: 7px;
  margin: 5px 10px 5px 0;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
.red {
  background-color: rgb(250, 229, 232);
}
.green {
  background-color: rgb(203, 236, 205);
}
.ask {
  margin: 10px 10px 10px 0;
}

.btn_vote {
  max-width: 40%;
  align-self: center;
  margin-top: 10px;
}
.img_dice {
  max-width: 40px;
}
.btn_go_to_questions {
  align-self: center;
  max-width: 50%;
}
.progress_result_container {
  flex-basis: 50%;
}
.progress_result {
  min-width: 100px;
  flex-basis: 60%;
  max-width: 300px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid rgb(175, 172, 172);
  margin: 10px 5px;
  justify-content: flex-end;
  padding: 0;
}
.not_enough {
  flex-basis: 20%;
  min-width: 20px;
  height: 100%;
  background-color: rgb(211, 207, 207);
}
.enough {
  flex-basis: 30%;
  min-width: 20px;
  height: 100%;
  background-color: rgb(215, 250, 207);
}
.overdo {
  flex-basis: 30%;
  min-width: 20px;
  height: 100%;
  background-color: rgb(248, 221, 224);
}
.yes {
  width: 60%;
  height: 100%;
  background-color: rgb(183, 243, 168);
}
.no {
  width: 40%;
  height: 100%;
  background-color: rgb(156, 156, 156);
}
.container_row {
  display: flex;
  justify-content: flex-start;
}

.container_for_gamers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
